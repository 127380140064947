<template>
    <v-container fluid class="pa-0 mb-6">
        <v-row>
            <v-col cols="12">
                <big-image
                    :image="get_image.img_10"
                    edit_title="services.land.title_image"
                    :title="$t('services.land.title_image')"
                    :button_name="$t('services.land.contact')"
                    redirect="contact-us"
                />
            </v-col>
            <v-col style="margin-top: 50px;" cols="12">
                <sub-text
                    :image="get_image.img_11"
                    edit_title="services.land.title"
                    :title="$t('services.land.title')"
                    edit_subtitle="services.land.subtitle"
                    :subtitle="$t('services.land.subtitle')"
                    :button_name="$t('services.land.button')"
                    redirect_name="contact-us"
                />
            </v-col>
            <v-col  style="height: 150px;" cols="12">
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import BigImage from '@/components/base/BigImageDisplay.vue';
import SubText from '@/components/base/SubTextImage.vue';
import { SERVICES } from '@/assets/img/images';
export default {
    components: {
        BigImage,
        SubText,
    },
    computed: {
        get_image() {
            return SERVICES;
        },
    }
    
}
</script>